#entete{
    padding-top:5vw;
}

#creationPresentation{
    max-height:85vh;
    overflow: hidden;
}
#videoPresentation{
    width: 100%;
    height: auto;
}

/* Carte de creation */

.carteCreation{
    margin-top:2em;
    margin-bottom:2em;
    width:100%;
    background-repeat: repeat;
    background-size: 100%;
    text-align: center;
    padding-top:14vw;
    padding-bottom:14vw;
    margin-left: auto;
    margin-right: auto;
    transition-duration: 200ms;
}
.carteCreation h2{
    color: white;
    font-size:2.5em;
    text-shadow: 1px 1px 5px black;
    text-align: center;
    transition-duration: 200ms;
}
.carteCreation:hover{
    animation-duration: 500ms;
    animation-name: zoomFond;
    background-size: 105%;
    opacity: 0.7;
}
.carteCreation:hover h2{
    transform: scale(1.1);
    transition-duration: 500ms;
    text-shadow: 1px 1px 5px black;
}


/* Creation Paysage */
#creation{
    text-align: center;
}
#titre{
    color: white;
}
#titre h2{
    text-align: center;
    font-size: 4em;
    padding-top: 2.5vh;
    padding-bottom:2.5vh;
    text-shadow: 1px 1px 5px black;
}

/* Carte de type */
.containerCarte{
    text-align:center;
    padding-top: 2em;
    padding-bottom:5em;
}
.carteType{
    margin-top:1.2vw;
    margin-bottom:1.2vw;
    margin-left: 2.2vh;
    margin-right: 2.2vh;
    width:18vw;
    height:18vw;
    background-repeat: repeat;
    background-size: 100%;
    text-align: center;
    display:inline-block;
    padding-top:8vw;
    overflow: hidden;
    transition-duration: 200ms;
}
.carteType h2{
    color: white;
    font-size:1.4em;
    text-shadow: 1px 1px 5px black;
    text-align: center;
    transition-duration: 200ms;
}
.carteType:hover{
    animation-duration: 500ms;
    animation-name: zoomFond;
    background-size: 105%;
    opacity: 0.7;
}
.carteType:hover h2{
    transform: scale(1.1);
    transition-duration: 500ms;
    text-shadow: 1px 1px 5px black;
}


/* Affichage photos */
#titreAffichagePhoto h2{
    color: white;
    text-align: center;
    font-size: 3em;
    padding-top: 2.5vh;
    padding-bottom:2.5vh;
    text-shadow: 1px 1px 5px black;
    animation-duration: 500ms;
    animation-name: smooth;
}

#sectionAffichagePhoto{
    text-align:center;
}
#sectionAffichagePhoto img{
    width:95%;
    padding-top:2vh;
    padding-bottom:1vh;
    animation-duration: 500ms;
    animation-name: slide;
}
#sectionAffichagePhoto{
    padding-bottom:4vh;
}

#rouge:hover h2{
    color: #ff9090;
}
#orange:hover h2{
    color: #ffbc90;
}
#jaune:hover h2{
    color: #ffd690;
}
#vertC:hover h2{
    color: #deff90;
}
#vert:hover h2{
    color: #90ffaa;
}
#bleu:hover h2{
    color: #90edff;
}
#violet:hover h2{
    color: #90b1ff;
}
#voilet:hover h2{
    color: #d390ff;
}
#rose:hover h2{
    color: #ff90d6;
}


@keyframes zoomFond {
    from {
        background-size: 100%;
        opacity: 1;
    }
  
    to {
        background-size: 105%;
        opacity: 0.7;
    }
}
@keyframes slide {
    from {
      opacity: 0;
      margin-top: 2vw;
    }
  
    to {
        opacity: 1;
        margin-top: 0vw;
    }
}


@keyframes smooth {
    from {
      opacity: 0;
    }
  
    to {
        opacity: 1;
    }
}

/* Taille écran médium */
@media (max-width: 1200px) {
    #entete{
        padding-top:9vw;
    }    
}
/* Taille écran petit */
@media (max-width: 767px) {
    #entete{
        padding-top:20vw;
    }    
    #enteteCreation{
        padding-top:10vh;
    }
    .carteCreation{
        padding-top:22vw;
        padding-bottom:22vw;
        width:80%;
    }
    .carteType{
        padding-top:22vw;
        padding-bottom:22vw;
        width:80%;
    }
    #titreAffichagePhoto h2{
        font-size: 2.5em;
        padding-left:1em;
        padding-right:1em;
    }
    .carteType h2{
        color: white;
        font-size:1.8em;
        text-shadow: 1px 1px 5px black;
        text-align: center;
    }
    .carteType{
        padding-top:15vw;
        padding-bottom:30vw;
    }
    #titre h2{
        font-size: 3.5em;
    }
    
}