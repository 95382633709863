#enteteContact{
    padding-top:6vw;
}
#titreContact{
    font-size:3em;
    color:white;
    animation-duration: 500ms;
    animation-name: smooth;
}
#titreContact h2{
    text-align:center;
    text-decoration: underline rgb(36, 123, 194);
}

.titreSectionContact{
    color:white;
    padding-bottom: 1em;
    padding-top:2em;
}
#sectionFormulaire, #sectionCoordones ,#sectionReseauxContact{
    padding-left: 4em;
    animation-duration: 500ms;
    animation-name: slide;
}

.contactForm{
    padding-left: 5vw;
    width:70%;
}
.contactForm input, #message{
    margin-bottom: 1em;
    width: 100%;
    height: 1.8em;
    border: none;
    border-radius: 4px;
}
#message{
    height: 8em;
}
.button:hover{
    background-color: rgb(36, 123, 194);
}
.contactForm h3{
    color: white;
    font-size: 0.9em;
    padding-left:0.5em;
    padding-bottom: 0.5em;
}

.formMessage{
    color: rgb(96, 250, 117);
    padding-bottom:2em;
}
.error{
    background-color: rgb(250, 82, 82);
}

.ligneFormulaire{
    display: flex;
}
.ligneFormulaire h3, .ligneFormulaire p{
    color: white;
    font-size: 1em;
}
.ligneFormulaire p:hover{
    color: rgb(43, 138, 226);
}

.logoContact img{
    width: 2.8em;
    margin:0.2em;
}
.logoContact img:hover{
    width: 3em;
    margin:0.1em;
}


/* Taille écran médium */
@media (max-width: 992px) {
    #enteteContact{
        padding-top:9vw;
    }    
    #sectionCoordones ,#sectionReseauxContact{
        display:none;
    }
}
/* Taille écran petit */
@media (max-width: 767px) {
    #enteteContact{
        padding-top:25vw;
    }    
    #sectionCoordones ,#sectionReseauxContact{
        display:none;
    }
}

@keyframes dongle {
    from {
        opacity: 0;
        margin-left: 0em;
    }

    to {
        opacity: 1;
        margin-left: 0.5em;
    }
}
@keyframes slide {
    from {
      opacity: 0;
      margin-top: 2vw;
    }
  
    to {
        opacity: 1;
        margin-top: 0vw;
    }
}
@keyframes smooth {
    from {
      opacity: 0;
    }
  
    to {
        opacity: 1;
    }
}