.footer {
    font-family: "AKsans";
    background-color: black;
    text-align: center;
    color: white;
    padding: 2em;
    min-height: 25vh;
}
h2{
    font-family: "Garnet Capitals";
    font-size: 1em;
    text-align: left;
    font-weight: bold;
}
h3{
    font-size: 0.8em;
    color: rgb(184, 184, 184);
    margin-right: 0.5em;
    margin-bottom: 0px;
    vertical-align: baseline;
}
p{
    font-size: 0.8em;
    color: rgb(148, 148, 148);
    margin-bottom: 0px;
}
.caseFooter p:hover{
    color: rgb(43, 138, 226);
}

.caseFooter{
    display: flex;
    margin-bottom: 0.6em;
}
.textFooter p{
    color:white;
    margin-bottom: 0.5em;
    text-align: left;
}

.logoFooter img{
    width: 2.8em;
    margin:0.2em;
    transition-duration:100ms
}
.logoFooter img:hover{
    width: 3em;
    margin:0.1em;
    transition-duration:200ms
}
#sectionConctact{
    margin-bottom:1em;
}
#sectionReseaux{
    margin-bottom:2em;
}