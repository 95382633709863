#entete{
    padding-top:6vw;
}
#titreMesProjets{
    font-size:3em;
    color:white;
    animation-duration: 500ms;
    animation-name: smooth;
    padding-bottom: 1em;
}
#titreMesProjets h2{
    text-align:center;
}
#rowSectionProjet{
    margin: 0;
}
.sectionPhotoProjet{
    min-height:60vh;
    background-repeat: repeat;
    background-size: 100%;
    background-position: center;
}
.sectionTextProjet{
    padding-top:5vh;
    padding-bottom: 3em;
}
.sectionTextProjet h2{
    font-size:2em;
    color: white;
    text-decoration: underline rgb(130, 251, 255);
}
.sectionTextProjet h3{
    font-size:1em;
    color: white;
}
.sectionTextProjet h4{
    font-size: 0.8em;
    color: rgb(184, 184, 184);
    vertical-align: baseline;
    padding-bottom:1.8em;
}
.sectionTextProjet p{
    font-size:1em;
    color: white;
    padding-left:2em;
}
.pLien{
    color: #ffd690!important
}
.pLien:hover{
    color: #f8b33b!important
}

.ligneCompetence h3{
    font-size:1.1em;
    color: white;
    padding-bottom:0.5em;
    padding-top:1.8em;
}

.ligneCompetence p{
    font-size:0.9em;
    color: white;
}
.ligneImgCompetence{
    display: block;
    text-align: center;
    font-size:0.8em;
}
.imgCompetence{
    display:inline-block;
}
.colLogiciels {
    padding-top: 1.5em;
}
.colLogiciels img{
    width:70px;
    margin:0.5em;
    margin-bottom:1em;
}
.colLogiciels h3{
    padding:0;
    padding-bottom: 1em;
}

#photoProjetHaut{
    display: none;
}

#rouge{
    text-decoration: underline #ff9090;
}
#orange{
    text-decoration: underline #ffbc90;
}
#jaune{
    text-decoration: underline #ffd690;
}
#vertC{
    text-decoration: underline #deff90;
}
#vert{
    text-decoration: underline #90ffaa;
}
#bleu{
    text-decoration: underline #90edff;
}
#violet{
    text-decoration: underline #90b1ff;
}
#voilet{
    text-decoration: underline #d390ff;
}
#rose{
    text-decoration: underline #ff90d6;
}

/* Projet Alpha */
#titreAlpha{
    text-decoration: underline rgb(130, 251, 255);
}
#photoAlpha{
    background: url('../img/centreInteret/fondVoile.jpg');
}


/* Taille écran médium */
@media (max-width: 992px) {
    #entete{
        padding-top:9vw;
    }
    #photoProjetHaut{
        display: block;
    }
    #photoProjetDroit{
        display: none;
    }
}
/* Taille écran petit */
@media (max-width: 767px) {
    #entete{
        padding-top:25vw;
    }
    .sectionPhotoProjet{
        min-height:30vh;
    }
}
