.navbar {
    font-family: "AKsans";
    background-color: black;
    color: white;
    justify-content: center;
    align-items: center;
    padding: 2vh;
    padding-left: 10vw;
    padding-right: 10vw;
    width: 100%;
    position: fixed;
    z-index:1;
}

.navigation{
    justify-content: right;
    align-items: right;
}
.listeNav{
    list-style-type: none;
    background-color: black;
    padding-left: 30vw;
    width: 100%;
    justify-content: right;
    align-items: right;
    position: relative;
    display: flex;
}
.item{
    margin-right: 2em;
    color: white;
    cursor: pointer;
}
#menuDeroulant{
    background-color:black;
    color: white;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 1em;
    display:none;
    transition-duration: 800ms;
    padding-top: 8em;
    width: 100%;
    z-index: 0;
}

li{
    font-family: "Garnet Capitals";
    font-size: 1em;
}
h1 a{
    text-decoration: none;
    color: white; 
}
h1 a:hover{
    color: white; 
}
h1{
    font-family: "Garnet Capitals";
    font-size: 1.5em;
}

li a{
    text-decoration: none;
    color: white; 
}
#aAccueil,#aProfessionnel,#aCreation,#aContact{
    transition-duration: 200ms;
}
#aAccueil:hover{
    color: rgb(255, 45, 108);
    transition-duration: 500ms;
}
#aProfessionnel:hover{
    color: rgb(130, 251, 255);
    transition-duration: 500ms;
}
#aCreation:hover{
    color: rgb(253, 224, 60);
    transition-duration: 500ms;
}
#aContact:hover{
    color: rgb(0, 153, 224);
    transition-duration: 500ms;
}

@media (min-width: 768px) {
    #boutonNav{
        display: none;
    }
}
@media (max-width: 768px) {
    .sectionListe{
        display: none;
    }
    .menuDeroulant{
        position: fixed;
    }
    .listeNav{
        display: block;
        padding-left: 10vw;
        font-size:1.5em;
    }
}


@keyframes enter {
    from {
      opacity: 0;
      transform: translate(0px, -10vh);
    }
  
    to {
      opacity: 1;
      transform: translate(0px, 0px);
    }
}



/**SVG******************/
#boutonNav{
    background-color: transparent;
    border: none;
    text-align: center;
    text-decoration: none;
}
svg{
    width:3em;
    height:2em;
}
svg:hover #bar-bottom{
    transform: translate(10px, 0);
    transition-duration: 500ms;
}
svg:hover #bar-top{
    transition-duration: 500ms;
    transform: translate(10px, 0);
}
svg:hover #bar-mid-r{
    transition-duration: 500ms;
    transform: translate(-10px, 0);
}
svg:hover #bar-mid-l{
    transition-duration: 500ms;
    transform: translate(-10px, 0);
}

#bar-bottom, #bar-top, #bar-mid-r, #bar-mid-l{
    fill:white;
    stroke:white;
}