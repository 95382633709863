#enteteAdmin{
    padding-top:8vw;
}

.sectionConnection{
    display:flex;
    width:50%;
    flex-direction:column;
    left: 50%;
    transform: translate(50%,0);
    align-items: center;
    background: white;
    border-radius: 1em;
    padding: 2em;
    margin-bottom: 10vw;
}
#textUser, #textMdp{
    width: 30%;
}

input{
    border-radius: 0.5em;
    border-color: white;
}
#submit{
    background-color: black;
    color: white;
    border-radius:1em;
    padding:0.5em;
    transition-duration:200ms;
    font-weight: bold;
}
#submit:hover{
    transform: scale(1.05);
    transition-duration:200ms;
    color: rgb(36, 123, 194);
}
#erreur{
    padding-top: 1em;
    color: rgb(248, 53, 53);
}

/* Taille écran médium */
@media (max-width: 992px) {
    #enteteAdmin{
        padding-top:12vw;
    }
}
/* Taille écran petit */
@media (max-width: 768px) {
    #enteteAdmin{
        padding-top:30vw;
    }
}