body{
    background-color: black;
}

#presentation {
    padding-block-start: 10px;
    padding-top: 35vh;
    padding-left: 1vw;
    padding-right: 1vw;  
    height: 80vh;
    justify-content: center;
    align-items: center;
    text-align: center;

    background-image: url(../img/accueil/opcaity.png) ,url(../img/accueil/fondaccueil1lg.jpg);
    background-repeat: repeat,no-repeat;
    background-size: 100%;
    background-attachment: fixed;
}

a{
    text-decoration: none;
}

#presentation h1{
    color: white;
    font-size:3em;
    text-shadow: 1px 1px 5px black;
    animation-duration: 2s;
    animation-name: slide;
}
#soulignePrenom{
    fill:none;
    stroke:white;
    stroke-linecap: round;
    stroke-width:10px;
    width:100%;
    height:20px;
    transform: scale(0.1);
    transform: translate(-150px, 0);
    animation-duration: 2s;
    animation-name: smooth;
}
#presentation p{
    color: white;
    font-size:1em;
    text-shadow: 1px 1px 1px rgb(236, 123, 78);
    font-weight: bold;
    animation-duration: 2s;
    animation-name: smooth;
}
#aPropos{
    display: flex;
    animation-duration: 1s;
    animation-name: smooth;
}
#aPropos img{
    width: 33%;
    height: 100%;
}


#textApropos{
    padding:3em;
}
#textApropos h2{
    color:white;
    font-size:1.5em;
}
#textApropos p{
    color:white;
    padding-top: 1em;
    padding-bottom: 2em;
    font-size: 0.9em;
}


/* Css Section Professionel */
#sectionProfessionel {
    height: 25vh;
    background-color: white;
    overflow: hidden;
    animation-duration: 1s;
    animation-name: smooth;
}

#sectionProfessionel img{
    height: 25vh;
    cursor: pointer;
}
#sectionProfessionel h2{
    color: black;
    font-size: 1.3em;
}
#sectionProfessionel:hover #svgProfessionelNoir{
    transform: translate(5px,0px);
    transition-duration: 500ms;
}
#sectionProfessionel img, #sectionProfessionel h2,#sectionProfessionel #svgProfessionelNoir{
    transition-duration: 200ms;
}
#sectionProfessionel:hover img{
    transform: scale(1.05);
    transition-duration: 500ms;
}
#sectionProfessionel:hover h2{
    transform: translate(5px,0px);   
    transition-duration: 500ms;
}
#sectionProfesionxs{
    background-image: url(../img/accueil/fondProfessionelxs.png);
}


/* Css Section Création */
#sectionCreation {
    height: 25vh;
    background-color: white;
    overflow: hidden;
    animation-duration: 1s;
    animation-name: smooth;
}

#sectionCreation img{
    height: 25vh;
    cursor: pointer;
}
#sectionCreation h2{
    color: black;
    font-size: 1.3em;
}
#sectionCreation img, #sectionCreation h2,#sectionCreation #svgCreationNoir{
    transition-duration: 200ms;
}
#sectionCreation:hover #svgCreationNoir{
    transform: translate(5px,0px);
    transition-duration: 500ms;
}
#sectionCreation:hover img{
    transform: scale(1.05);
    transition-duration: 500ms;
}
#sectionCreation:hover h2{
    transform: translate(5px,0px);   
    transition-duration: 500ms;
}
#sectionCreationxs{
    background-image: url(../img/accueil/fondCreationxs.png);
}


/* Css Section Navigation */
.sectionNavigation{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    animation-duration: 1s;
    animation-name: smooth;
}   
.flecheNavigation{
    height: 10vh;
}
.sectionNavigationxs{
    display: none;
    height: 25vh;
    background-size: 100%;
    background-repeat: no-repeat;
    justify-content: center;
    align-items: center;
}
.sectionNavigationxs h2{
    text-align: center;
    color: white;
    font-size: 1.5em;
    text-shadow: 1px 1px 8px black;
}

@keyframes slide {
    from {
      opacity: 0;
      padding: 1em;
    }
  
    to {
        opacity: 1;
        padding: 0em;
    }
}

@keyframes smooth {
    from {
      opacity: 0;
    }
  
    to {
        opacity: 1;
    }
}

/* Taille écran médium */
@media (max-width: 1100px) { 
    #presentation {
        padding-top: 35vh;

        background-image: url(../img/accueil/opcaity.png) ,url(../img/accueil/fondaccueil1md.jpg);
        background-repeat: repeat, no-repeat;
        background-size: 100%;
        background-attachment: fixed;
    }
    
}
/* Taille écran petit */
@media (max-width: 720px) {
    #presentation {
        padding-top: 30vh;
        height: 60vh;

        background-image: url(../img/accueil/opcaity.png) ,url(../img/accueil/fondaccueil1xs.jpg);
        background-repeat: repeat, no-repeat;
        background-size: 100%;
        background-attachment: fixed;
    }
    .sectionNavigationxs{
        display: flex;
    }
    #sectionProfessionel{
        display: none;
    }
    #sectionCreation{
        display: none;
    }
    #aPropos img{
        display: none;
    }
    #soulignePrenom{
        transform: translate(-21vw, 0);
    }
}