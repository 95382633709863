#enteteProfessionnel{
    padding-top:8vw;
}
/* Section resumer */
#sectionResumer{
    padding-bottom: 10em;
}
#photoPP{
    text-align: center;
}
#photoPP img{
    width: 90%;
}
#colonneInformations{
    padding-bottom: 2em;
}
.informations{
    font-size:1.5em;
}
.informations p{
    color: white;
    padding-top: 0.5em;
}
.informations a{
    text-decoration: none;
    color:white;
}
.informations a:hover{
    color:rgb(130, 251, 255);
}
#sectionResumer h2{
    color:white;
    padding-top:0.1em;
    font-size: 2em;
    text-decoration: underline rgb(130, 251, 255);
}
#textResumer p{
    font-size:1em;
    color:white;
    padding-bottom:1em;
    animation-duration: 500ms;
    animation-name: smooth;
}
#zoneCompetence{
    padding-top:1em;
    animation-duration: 500ms;
    animation-name: slideCompetence;
}
#titreCompetence{
    padding-bottom: 1em;
}
.textImgCompetence{
    text-align:center;
    padding-bottom: 1em;
}
.textImgCompetence img{
    width:60%;
}
.textImgCompetence h3{
    padding-top:1em;
    font-size:1em;
    color:white;
    text-align: center;
}


/* Section Formation */

#sectionFormation{
    padding-top:1em;
    background-color: rgb(104, 225, 255);
}
#sectionFormation h2{
    font-size:1.7em;
    padding-left: 2em;
    padding-bottom:0.5em;
    color:white;
}
.formation{
    height: auto;
    position: relative;
    text-align: center;
    background-color: black;
}
.formation img{
    width:80%;
}
.colonneImage img{
    width:90%;
    transition-duration: 200ms;
}
.imageColonne{
    text-align: center;
}
#ligneImage{
    width:80%;
    margin: 0 auto;
    padding-top: 2em;
    padding-bottom: 2em;
}
#ligneImage img{
    width:90%;
}
.colonneImage:hover img{
    transform: scale(1.05);
    transition-duration: 800ms;
}
#imgBarDroit{
    display:none;
}
#imgBarGauche{
    display:none;
}
#ligneImage #imgBar{
    width:100%;
}
#formationXs{
    padding-top: 1em;
    padding-bottom: 1em;;
    display:none;
    background-image: url(../img/professionnel/imgBarXs.svg);
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: center;
}
#formationXs img{
    width:80%;
    padding-top:2vw;
    padding-bottom: 4vw;
}
#formationXs img:hover{
    transform: scale(1.05);
    transition-duration: 800ms;
}


/* Section expérience */

#sectionExperience{
    padding-bottom: 2em;
    text-align: center;
    background-color: black;
}
#titreExperiences{
    padding-top:1em;
    padding-bottom:1em;
    background-color: #FFCA00;
    font-size:1.2em;
    padding-left: 2em;
    margin-bottom:1em;
    color: white;
}
.experience h3{
    font-size:1.5em;
    color: white;
    animation-duration: 500ms;
    animation-name: slide;
}
.experience h4{
    font-size:1.1em;
    color: white;
    animation-duration: 500ms;
    animation-name: slide;
}
.experience h5{
    font-size:1em;
    color: white;
    animation-duration: 500ms;
    animation-name: slide;
}
.experience p{
    font-size:1em;
    color: white;
    margin-bottom:1em;
    animation-duration: 500ms;
    animation-name: slide;
}
.experience img{
    margin:1em;
    width:50%;
    animation-duration: 500ms;
    animation-name: smooth;
}


/* Section Projet */
#sectionProjet{
    background-image: url('../img/professionnel/fondProjet.png');
    background-repeat: repeat,no-repeat;
    background-size: 100%;
    height: 12vh;
    overflow: hidden;
    transition-duration: 200ms;
}
#sectionProjet h2{
    padding-top: 1em;
    text-align: center;
    font-size:1.8em;
    color: white;
    text-shadow: 1px 1px 2px black;
    transition-duration: 200ms;
}
#sectionProjet:hover{
    animation-duration: 500ms;
    animation-name: zoomFond;
    background-size: 105%;
    opacity: 0.7;
}
#sectionProjet:hover h2{
    transform: scale(1.1);
    transition-duration: 500ms;
    color: rgb(27, 104, 167);
}

/* Section interet */
#titreInteret{
    padding-top:1em;
    padding-bottom:1em;
    background-color: #ff0062;
    font-size:1.2em;
    padding-left: 2em;
    color: white;
}
#sectionInterer{
    margin-right: 0;
    margin-left: 0;
}
#centreVoile{
    background-image: url('../img/professionnel/interetVoile.png');
    background-repeat: repeat;
    background-size: 100%;
    height: 40vh;
    overflow: hidden;
    transition-duration: 200ms;
}
#centreVoile h2{
    font-size:3em;
    color: white;
    text-align: center;
    padding-top:15vh;
    text-shadow: 1px 1px 2px black;
    transition-duration: 200ms;
}
#centreVoile:hover{
    animation-duration: 500ms;
    animation-name: zoomFond;
    background-size: 105%;
    opacity: 0.7;
    cursor: pointer;
}
#centreVoile:hover h2{
    transform: scale(1.1);
    transition-duration: 500ms;
    color: rgb(255, 212, 176);
}
#centreAudioVisuel{
    background-image: url('../img/professionnel/interetAudio.png');
    background-repeat: repeat;
    background-size: 100%;
    background-position: center;
    height: 40vh;
    overflow: hidden;
    transition-duration: 200ms;
}
#centreAudioVisuel h2{
    font-size:3em;
    color: white;
    text-align: center;
    padding-top:15vh;
    text-shadow: 1px 1px 2px black;
    transition-duration: 200ms;
}
#centreAudioVisuel:hover{
    animation-duration: 500ms;
    animation-name: zoomFond;
    background-size: 105%;
    opacity: 0.7;
    cursor: pointer;
}
#centreAudioVisuel:hover h2{
    transform: scale(1.1);
    transition-duration: 500ms;
    color: #7dd5dd;
}

/* Annimation */

@keyframes zoom {
    from {
        background-size: 100%;
    }
  
    to {
        background-size: 105%;
    }
}

@keyframes zoomFond {
    from {
        background-size: 100%;
        opacity: 1;
    }
  
    to {
        background-size: 105%;
        opacity: 0.7;
    }
}


@keyframes slideDiagramme {
    from {
      opacity: 0;
      padding-top: 2em;
    }
  
    to {
        opacity: 1;
        padding-top: 0em;
    }
}
@keyframes slide {
    from {
      opacity: 0;
      padding-top: 1em;
    }
  
    to {
        opacity: 1;
        padding-top: 0em;
    }
}
@keyframes slideCompetence {
    from {
      opacity: 0;
      padding-top: 2em;
    }
  
    to {
        opacity: 1;
        padding-top: 1em;
    }
}

@keyframes smooth {
    from {
      opacity: 0;
    }
  
    to {
        opacity: 1;
    }
}

/* Taille écran médium */
@media (max-width: 992px) {
    #enteteProfessionnel{
        padding-top:12vw;
    }
    #imgBarDroit{
        display:flex;
    }
    #imgBarGauche{
        display:flex;
    }
    #imgBar{
        display:none;
    }
}
/* Taille écran petit */
@media (max-width: 768px) {
    #enteteProfessionnel{
        padding-top:30vw;
    }
    #formationLg{
        display: none;
    }
    #formationXs{
        display: flex;
    }
    #sectionProjet h2{
        padding-top: 0.5em;
    }
    #sectionProjet{
        height: 8vh;
    }
    #photoPP img{
        width: 50%;
        padding-bottom: 2em;
    }
}