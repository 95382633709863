@font-face {
  font-family: "AKsans";
  src: url("css/police/AKsans.otf");
}

@font-face {
  font-family: 'Garnet Capitals';
  src: local('Garnet Capitals Thin'), local('GarnetCapitals-Thin'),
      url('css/police/GarnetCapitals-Thin.woff2') format('woff2');
  font-style: normal;
}

@font-face {
  font-family: 'Garnet Capitals';
  src: local('Garnet Capitals Regular'), local('GarnetCapitals-Regular'),
      url('css/police/GarnetCapitals-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Garnet Capitals Inline';
  src: local('Garnet Capitals Inline'), local('GarnetCapitals-Inline'),
      url('css/police/GarnetCapitals-Inline.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Garnet Capitals';
  src: local('Garnet Capitals Bold'), local('GarnetCapitals-Bold'),
      url('css/police/GarnetCapitals-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Garnet Capitals';
  src: local('Garnet Capitals Black'), local('GarnetCapitals-Black'),
      url('css/police/GarnetCapitals-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Garnet Script';
  src: local('Garnet Script Regular'), local('GarnetScript-Regular'),
      url('css/police/GarnetScript-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Garnet Capitals';
  src: local('Garnet Capitals Light'), local('GarnetCapitals-Light'),
      url('css/police/GarnetCapitals-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}



.body {
  font-family: "AKsans";
}

code {
  font-family: "AKsans",serif;
}

#error{
  justify-content: center;
  align-items: center;
  text-align: center;
}
#error img{
  max-width: 80%;
  min-height: 50vh;
}
#rowRigth{
  margin-right: 0;
}