#sectionPhotoCentreVoile{
    background: url('../img/centreInteret/fondVoile.jpg');
    background-repeat: repeat;
    background-size: 100%;
    min-height:70vh
}
.sectionTexteCentre{
    padding-top:10vh;
    padding-bottom: 3em;
}
.sectionTexteCentre h2{
    font-size:2em;
    color: white;
    padding-left:1em;
}
.sectionTexteCentre p{
    font-size:1.1em;
    color: white;
    padding-top:2em;
    padding-left:2em;
    padding-right:10em;
}
#textVoile h2{
    text-decoration: underline rgb(37, 139, 255);
}
#textAudioVisuel h2{
    text-decoration: underline rgb(37, 139, 255);
    padding-left: 2em;
}
#textAudioVisuel p{
    padding-left: 4em;
}
#sectionPhotoCentreAudioVisuel{
    background: url('../img/centreInteret/fondAudio.jpg');
    background-repeat: repeat;
    background-size: 100%;
    min-height:70vh;
}

#aRedirection{
    padding: 0;
}

#sectionRedirectionCreation {
    min-height: 20vh;
    background-color: white;
    animation-duration: 1s;
    overflow: hidden;
    animation-name: smooth;
    display: flex;
}
#textRedirection{
    display: flex;
    text-align: center;
    min-width: 50%;
}

#sectionRedirectionCreation img{
    cursor: pointer;
    max-width: 50%;
}
#sectionRedirectionCreation h3{
    color: black;
    font-size: 1.8em;
    padding-top:8vh;
    padding-left: 20%;
}
#sectionRedirectionCreation svg{
    height:2em;
    margin-top:8vh;
}
#sectionRedirectionCreation:hover #svgCreationNoir{
    transform: translate(5px,0px);
    transition-duration: 500ms;
}
#sectionRedirectionCreation:hover img{
    transform: scale(1.05);
    transition-duration: 500ms;
}
#sectionRedirectionCreation:hover h3{
    transform: translate(5px,0px);   
    transition-duration: 500ms;
}

#sectionRedirectionCreationxs{
    display:none;
    background: url('../img/centreInteret/fondRedirectionxs.png');
    background-repeat: repeat;
    background-size: 100%;
    min-height:18vh
}
#sectionRedirectionCreationxs h3{
    color: white;
    font-size:2em;
    text-align: center;
    padding-top:7vh;
    text-shadow: 1px 1px 8px black;
}

/* Taille écran médium */
@media (max-width: 1360px) { 
    .rowCentreInteret{
        display: block;
    }
    .sectionTexteCentre{
        width: 100%;
    }
    #sectionPhotoCentreAudioVisuel{
        width: 100%;
        background-position: bottom -500px right 0px;;
    }
    #sectionPhotoCentreVoile{
        width: 100%;
        background-position: bottom -500px right 0px;;
    }
}
/* Taille écran petit */
@media (max-width: 720px) {
    .sectionTexteCentre p{
        padding-right:2em;
    }
    #sectionPhotoCentreAudioVisuel{
        width: 100%;
        background-position: bottom 0px right 0px;;
    }
    #sectionPhotoCentreVoile{
        width: 100%;
        background-position: bottom 0px right 0px;;
    }
    #sectionRedirectionCreation{
        display: none;
    }
    #sectionRedirectionCreationxs{
        display: block;
    }
}